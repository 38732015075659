import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import "./Styles/GameFAQs.scss";

class GameFAQs extends Component {

    render() {
        return (
            <div className="gameFAQs">
                <div className="gameFAQs__sectionTitle">
                    <h3>Game FAQs</h3>
                </div>

                <div className="question-block">
                    <a className="accordion-toggle question-title" data-toggle="collapse" data-parent="#accordion1" href="#gamesCollapseOne">
                        Cosmetic Condition
                    </a>
                    <div id="gamesCollapseOne" className="accordion-body collapse">  
                        <div className="accordion-inner">
                            <p>This is whatever you want it to be, but here are some tips that might help:</p>
                            <ol>
                                <li>Perfect - This should be reserved for complete perfection, whatever that means to you.</li>
                                <li>Good - If it's so close to being perfect, but there's that one thing that you just can't get over.</li>
                                <li>Fair - Bearable, but there are too many issues to call it good.</li>
                                <li>Bad - As a collector, you need a new one. It wasn't as described, it looked better online than when you got it in the mail, etc.</li>
                                <li>None - Should be there, but you don't have it. E.g. You're missing the box for <Link to="/games/164" >{"glover"}</Link>.</li>
                                <li>N/A - Doesn't exist. This is reserved for items that normally exist, but this is a special case and doesn't. E.g. Dreamcast games normally have spine cards, but the <Link to="/games/112" >{"Dream Passport"}</Link> for dreamcast doesn't.</li>
                            </ol>
                        </div>  
                    </div>
                </div>
            </div>
        )
    }
}

export default GameFAQs;