import React, { useEffect, useState, useRef } from 'react';
import About from './Components/About';
import Contact from './Components/Contact';
import Footer from './Components/Layout/Footer';
import Header from './Components/Layout/Header';
import Help from './Components/Help';
import Home from './Components/Home';
import Login from './Components/Auth/Login';
import SignUp from './Components/Auth/SignUp';
import Browse from './Components/Browse/Browse';
import Profile from './Components/User/Profile';
import Collection from './Components/User/Collection';
import SearchResults from './Components/Search/SearchResults';
import BrowseGame from './Components/Item/Item';
import InventoryItem from './Components/Inventory/Item';
import Regions from './Components/Admin/Regions';
import { BrowserRouter, Route } from 'react-router-dom'
import publicIp from 'public-ip';
import './scss/app.scss';

const App = () => {
	const [ loggedIn, setLoggedIn ] = useState(false);
	const [ ip, setIp ] = useState('');
	const [ firstLoad, setFirstLoad ] = useState(true);
	const [ isAdmin, setIsAdmin ] = useState(false);
	const [ currentUser, setCurrentUser ] = useState({});
	const isLoading = useRef(false);

	useEffect(() => {
		setFirstLoad(false);
		if (firstLoad) {
			(async () => {
				await publicIp.v4().then((response) => {
					setIp(response);
				});
			})();
		}

		if (ip !== "") {
			// TODO: WARNING this only works with one cookie, if we plan on doing more we need to refactor
			const fullCookie = document.cookie;
			const cookieArray = fullCookie.split("=");
			const cookie = cookieArray[1];
			checkCookie(cookie);
		}
			// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ip])

	const checkCookie = (cookie) => {
        const loginQuery = `
query {
  user (cookie: "${cookie}", ip: "${ip}") {
  	cookie
  	alias
  	id
  	admin
  }
}
    `;

        callFetch(loginQuery, {}, (response) => {
            if (response.data.user) {
            	setCurrentUser(response.data.user);
                const cookie = response.data.user.cookie;
                if (cookie) {
                    setLoggedIn(true);
                    document.cookie = "ooug=" + cookie + ";path=/; SameSite=Strict; Max-Age=86400";
                }
                if (response.data.user.admin) {
                	setIsAdmin(true);
                }
            }
        });
	}

	const checkAdmin = (cookie, ip, callback) => {
        const loginQuery = `
query {
  user (cookie: "${cookie}", ip: "${ip}") {
  	cookie
  	alias
  	id
  	admin
  }
}
    `;

        callFetch(loginQuery, {}, (response) => {
            if (response.data.user) {
                if (response.data.user.admin) {
                	setIsAdmin(true);
                	callback();
                }
            }
        });
	}

	const callFetch = (query, variables, callback, onError, changeLoading = false) => {
		if (changeLoading) {
			isLoading.current = true;
		}
		fetch(`https://${process.env.NODE_ENV === "development" ? "dev-" : ""}api.ooug.net/`, {
			method: 'POST',
			headers: {
				'Accept': 'application/json, text/html'
			},
			body: JSON.stringify({
				query,
				variables
			}),
			credentials: 'same-origin',
		})
		.then(response => {
			if (response.status === 200) {
				return response.json();
			} else {
				response.text()
				.then((text) => {
					throw text;
				})
				.catch((err) => {
				  // Todo: snackbar or some error thing
				  var html = document.createElement('div');
				  html.innerHTML = err;
	              // const returnedError = html.getElementsByClassName('debug-title');
	          });
	    		return { 'errors': ['error'] };
	    	}
	    })
	    .then((response) => {
	    	if (callback) {
	    		callback(response);
	    	}
	    	if (changeLoading) {
	    		isLoading.current = false;
	    	}
	    })
	    .catch((err) => {
	      	// Todo: snackbar or some error thing
	      	if (onError) {
	      		onError(err);
	      	}
	      });
	};


    const checkLoggedIn = (cookie, ip) => {
        const loginQuery = `
query {
  user (cookie: "${cookie}", ip: "${ip}") {
    cookie
    alias
    id
    admin
  }
}
    `;

        callFetch(loginQuery, {}, (response) => {
            if (response.data.user) {
                const cookie = response.data.user.cookie;
                const admin = response.data.user.admin;
                if (cookie) {
                    setLoggedIn(true);
                } else {
                    setLoggedIn(false);
                }
                if (admin) {
                    setIsAdmin(true);
                }
            }
        });
    }

		return (
			<BrowserRouter>
				<div>
					<Header
						loggedIn={loggedIn}
						setLoggedIn={setLoggedIn}
						isAdmin={isAdmin}
						currentUser={currentUser}
						callFetch={callFetch}
						setIsAdmin={setIsAdmin}
						checkLoggedIn={checkLoggedIn}
					/>
					<main>
						<Route exact path="/*"/>
						<Route exact path="/" component={Home} />
						<Route exact path="/about" component={About} />
						<Route exact path="/contact" component={Contact} />
						<Route exact path="/help" component={Help} />
						<Route exact path="/login" component={() =>
								<Login
									callFetch={callFetch}
									loggedIn={loggedIn}
									setLoggedIn={setLoggedIn}
									ip={ip}
									setCurrentUser={setCurrentUser}
								/>
							}
						/>
						<Route exact path="/signup" component={SignUp} />
						<Route exact path="/search/:query" component={(props) =>
								<SearchResults
									query={props.match.params.query}
									callFetch={callFetch}
									loggedIn={loggedIn}
									isLoading={isLoading}
								/>
							}
						/>
						<Route exact path="/browseGames" component={() =>
								<Browse
									listType={"games"}
									callFetch={callFetch}
									loggedIn={loggedIn}
									isLoading={isLoading}
								/>
							}
						/>
						<Route exact path="/browseConsoles" component={() =>
								<Browse
									listType={"consoles"}
									callFetch={callFetch}
									loggedIn={loggedIn}
									isLoading={isLoading}
								/>
							}
						/>
						<Route exact path="/browseAccessories" component={() =>
								<Browse
									listType={"accessories"}
									callFetch={callFetch}
									loggedIn={loggedIn}
									isLoading={isLoading}
								/>
							}
						/>
						<Route exact path="/browseGames/:id" component={(props) =>
								<BrowseGame
									listType={"games"}
									callFetch={callFetch}
									loggedIn={loggedIn}
									id={props.match.params.id}
									isLoading={isLoading}
								/>
							}
						/>
						<Route exact path="/profile" component={(props) =>
								<Profile
									callFetch={callFetch}
									loggedIn={loggedIn}
									currentUser={currentUser}
									ip={ip}
									isLoading={isLoading}
								/>
							}
						/>
						<Route exact path="/gameCollection" component={(props) =>
								<Collection
									callFetch={callFetch}
									currentUser={currentUser}
									ip={ip}
									context={"games"}
								/>
							}
						/>
						<Route exact path="/inventoryGame/:id" component={(props) =>
								<InventoryItem
									callFetch={callFetch}
									currentUser={currentUser}
									listType={"games"}
									id={props.match.params.id}
								/>
							}
						/>
						<Route exact path="/regions" component={(props) =>
								<Regions
									callFetch={callFetch}
									isAdmin={isAdmin}
									checkAdmin={checkAdmin}
								/>
							}
						/>
					</main>
					<Footer />
				</div>
			</BrowserRouter>
		)
    }

export default App;
