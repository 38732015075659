import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';

const Home = () => {
    const [ query, setQuery ] = useState('');

    const useStyles = makeStyles(theme => ({
        homeContainer: {
            display: "flex",
            flexDirection: "column"
        },
        oougHeader: {
            fontWeight: "bold",
            textAlign: "center",
            fontFamily: "Arial Black",
            marginTop: "12rem",
            fontSize: "2.375", //TODO: change this to rem
        },
        subText: {
            textAlign: 'center',
            fontFamily: "arial",
            marginTop: "1rem"
        },
        searchBar: {
            borderRadius: "15px",
            border: "1px solid #ccc",
            paddingLeft: "2rem"
        },
        search: {
            textAlign: "center"
        }
    }));


    const classes = useStyles();

    const search = (e) => {
        console.log("KEY", e.key);
        if (e.key === "Enter") {
            window.location = `/search/${query}`;
        }
    }

        return (
            <div className={classes.homeContainer}>
                <h1 className={classes.oougHeader}>{"OOUG"}</h1>
                <div className={classes.search}>
                    <SearchIcon />  
                    <input
                        type={"text"}
                        onChange={(e) => {
                            setQuery(e.target.value)
                        }}
                        placeholder={"Search for a game"}
                        className={classes.searchBar}
                        onKeyDown={search}
                    />
                </div>
                <p className={classes.subText}>
                    {"We collect games, because we love games."}
                </p>
            </div>
        );
    }

export default Home;
