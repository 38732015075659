import React, { Component } from 'react';
import Layout from './Layout';

import './Styles/Footer.scss';

class Footer extends Component {
    render() {
        return (
            <footer className="footer">
                <Layout>
                    <ul className="footer__left">
                        <li><a href="/">www.ooug.net</a></li>
                    </ul>
                    <ul className="footer__right">
                        <li><a href="http://www.ooug.net">Corporate</a></li>
                    </ul>
                </Layout>
            </footer>
        );
    }
}

export default Footer;