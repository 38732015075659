import React from 'react';
import Button from 'react-bootstrap/Button';
import { makeStyles } from '@material-ui/core/styles';

const ConfirmModal = ({ title, onConfirm, onCancel, itemTitle }) => {
    const useStyles = makeStyles(theme => ({
        root: {
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "column",
            alignItems: "center",
            fontFamily: '"Lucida Grande", "Georgia", "Arial"',
            backgroundColor: "white",
            paddingBottom: "1rem",
            justifyContent: "center"
        },
        buttonGroup: {
            display: "flex",
            justifyContent: "space-around",
            width: "100%"
        },
        title: {
            marginBottom: "2.5rem"
        },
        cancel: {
            backgroundColor: "#911",
            border: "none"
        }
    }));

  const classes = useStyles();

        return (
            <div
                className={classes.root}
            >
                <h4
                    className={classes.title}
                >
                    {title}
                </h4>
                <div
                    className={classes.buttonGroup}
                >
                    <Button
                        className={classes.confirm}
                        onClick={onConfirm}
                        color={"primary"}
                    >
                        {`Remove ${itemTitle}`}
                    </Button>
                    <Button
                        className={classes.cancel}
                        color={"secondary"}
                        onClick={onCancel}
                    >
                        {`Never Mind`}
                    </Button>
                </div>
            </div>
        );
    }

export default ConfirmModal;
