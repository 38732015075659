import React from 'react';
import { ContainerDiv } from '../Styles/Components/Layout';

import './Styles/Layout.scss';

const Layout = ({ signedIn, children }) => {
        return (
            <div className="layout__inner">
                <ContainerDiv className="layout__container">
                    <nav className="layout__nav">
                        {children}
                    </nav>
                </ContainerDiv>
            </div>
        );
}

export default Layout;
