import React, { useState, useEffect } from 'react';
// import Button from 'react-bootstrap/Button';
import { makeStyles } from '@material-ui/core/styles';
import publicIp from 'public-ip';

const Regions = ({ callFetch, isAdmin, checkAdmin }) => {

    const [ regions, setRegions ] = useState([]);

    useEffect(() => {
        // todo: check admin, if no then do nothing, take from admin props
        (async () => {
            await publicIp.v4().then((response) => {
                const fullCookie = document.cookie;
                const cookieArray = fullCookie.split("=");
                checkAdmin(cookieArray[1], response, () => {
                    regionData();
                });
            });
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAdmin])

    const useStyles = makeStyles(theme => ({
        root: {
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "column",
            alignItems: "center",
            fontFamily: '"Lucida Grande", "Georgia", "Arial"',
            backgroundColor: "white",
            paddingTop: "2rem",
            justifyContent: "center"
        },
        buttonGroup: {
            display: "flex",
            justifyContent: "space-around",
            width: "100%"
        },
        title: {
            marginBottom: "2.5rem"
        },
        regionList: {
            display: "flex",
            flexDirection: "column",
            maxWidth: '60rem',
            justifyContent: "space-around"
        },
        regionRow: {
            display: "flex",
            marginBottom: "2rem"
        },
        regionTitle: {
            marginRight: '3rem',
            minWidth: "6rem"
        }
    }));


    const regionData = (cookie, ip) => {
        const loginQuery = `
query {
  regions {
    id
    title
    country
  }
}
    `;

        callFetch(loginQuery, {}, (response) => {
            if (response.data.regions) {
                setRegions(response.data.regions);
            }
        });
    }

  const classes = useStyles();

        return (
            <div
            >
                {isAdmin && (
                    <div
                    className={classes.root}
                    >
                        <h1
                            className={classes.title}
                        >
                            {"Regions"}
                        </h1>
                        <div
                            className={classes.regionList}
                        >
                            {regions.map((region, idx) => {
                                return (
                                    <div
                                        key={`region-${idx}`}
                                        className={classes.regionRow}
                                    >
                                        <div
                                            className={classes.regionTitle}
                                        >
                                            {region.title}
                                        </div>
                                        <div>
                                            {region.country}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                )}
            </div>
        );
    }

export default Regions;
