import React, { useEffect, useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from 'react-bootstrap/Button';
import publicIp from 'public-ip';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';

const New = ({ callFetch, listType, onClose, defaultConsole }) => {
    const cookie = useRef("");
    const ip = useRef("");
    const [ regionList, setRegionList ] = useState([]);
    const [ consoleList, setConsoleList ] = useState([]);
    const [ selectedRegion, setSelectedRegion ] = useState(1);
    const [ selectedConsole, setSelectedConsole ] = useState(1);
    const [ showJpData, setShowJpData ] = useState(true);
    const [ japTitle, setjapTitle ] = useState('');
    const [ engTitle, setEngTitle ] = useState('');
    const [ ean, setEan ] = useState('');

    useEffect(() => {
        getRegions();
        getConsoles();
        if (defaultConsole) {
            setSelectedConsole(defaultConsole);
        }
        (async () => {
            await publicIp.v4().then((response) => {
                const fullCookie = document.cookie;
                const cookieArray = fullCookie.split("=");
                cookie.current = cookieArray[1];
                ip.current = response
            });
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const useStyles = makeStyles(theme => ({
        root: {
            display: "flex",
            alignItems: "center",
            flexDirection: "column"
        },
        form: {
            maxWidth: "10rem"
        },
        gameTitle: {
            textAlign: 'center',
            fontFamily: '"Lucida Grande", "Georgia", "Arial"'
        },
        newTooltip: {
            marginLeft: '5px',
            '&:hover': {
                '&:before': {
                    position: 'absolute',
                    content: 'attr(tooltip)',
                    display: 'inline-block',
                    marginLeft: '20px',
                    background: 'none repeat scroll #feffcd',
                    border: 'solid 1px black',
                    '-moz-border-radius': '10px',
                    '-webkit-border-radius': '10px',
                    borderRadius: '10px',
                    color: '#000',
                    padding: '10px 10px',
                    maxWidth: '200px',
                },
                '&:after': {
                    content: "",
                    borderColor: '#f3961c',
                    borderWidth: '10px 50px 10px 0',
                    bottom: 'auto',
                    left: '-50px',
                    top: '16px',
                }
            }
        },
        newBody: {
            marginTop: '100px'
        },
        japTitle: {

        },
        right: {
            display: "flex",
            flexDirection: "column",
            // marginLeft: "auto",
            // marginRight: "5rem"
        },
        left: {
            display: "flex",
            flexDirection: "column",
            // marginLeft: "5rem",
            marginTop: "1.5rem"
        },
        data: {
            display: "flex",
            width: "100%",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "space-between"
        },
        consoleForm: {
            // position: "absolute",
            // left: "0",
            // top: "8.3rem",
            // marginLeft: "5rem"
        }
    }));


    const classes = useStyles();

    const getRegions = () => {
        const query = `
query {
  regions {
    id
    title
    country
  }
}
    `;

        callFetch(query, {}, (response) => {
            if (response.data.regions) {
                setRegionList(response.data.regions);
            }
        });
      };

    const getConsoles = () => {
        const query = `
query {
  generalConsoles {
    id
    eng_title
  }
}
    `;

        callFetch(query, {}, (response) => {
            if (response.data.generalConsoles) {
                setConsoleList(response.data.generalConsoles);
            }
        });
      };

      const createGame = () => {
        const query = `
mutation {
  createGame (cookie:"${cookie.current}", ip:"${ip.current}", japTitle:"${japTitle}", engTitle:"${engTitle}", ean:"${ean}", region:${selectedRegion}, console:${selectedConsole}) {
    ean
    id
    eng_title
    jap_title
    spine_card_exists
    region {
      title
      country
    }
    console {
      eng_title
      jap_title
    }
  }
}
    `;

        callFetch(query, {}, (response) => {
            if (response.data.createGame.id) {
                // window.location = `/browseGames/${response.data.createGame.id}`;
                onClose();
            }
        });
      };

      const handleChangeRegion = (event) => {
        setSelectedRegion(event.target.value);
        setjapTitle('');
        if (event.target.value === 1) {
            setShowJpData(true);
        } else {
            setShowJpData(false);
        }
      }

      const onChangeEan = (ev) => {
        if (ev.target.value.indexOf(" ") >= 0) {
            // todo: have some error show up probably
        }
        else {
            setEan(ev.target.value);
        }
      }

    return (
            <div className={classes.root}>
                <FormControl
                    className={classes.form}
                >
                    <InputLabel htmlFor="Region">{"Region"}</InputLabel>
                    <Select
                        inputProps={{
                            name: 'Region',
                            id: 1,
                        }}
                        value={selectedRegion}
                        onChange={handleChangeRegion}
                    >
                        {regionList.map((value) => {
                            return <MenuItem key={`region-${value.id}`} value={value.id}>{`${value.title}`}</MenuItem>;
                        })}
                    </Select>
                  </FormControl>
                  <div
                    className={classes.data}
                  >
                <div
                    className={classes.left}
                >
                <FormControl
                    className={classes.consoleForm}
                >
                    <InputLabel htmlFor="console">{"Console"}</InputLabel>
                    <Select
                        inputProps={{
                            name: 'console',
                            id: 'console',
                        }}
                        value={selectedConsole}
                        onChange={(ev) => {
                            setSelectedConsole(ev.target.value)
                        }}
                    >
                        {consoleList.map((value) => {
                            return <MenuItem key={`console-${value.id}`} value={value.id}>{`${value.eng_title}`}</MenuItem>;
                        })}
                    </Select>
                  </FormControl>
                  <FormControl
                            className={classes.ean}
                        >
                            <TextField
                                id="ean"
                                label="EAN"
                                margin="normal"
                                rel="ean"
                                fullWidth
                                multiline
                                value={ean}
                                onChange={onChangeEan}
                            />
                        </FormControl>
                  </div>
                <div
                    className={classes.right}
                >
                    {showJpData && (
                        <FormControl
                            className={classes.japTitle}
                        >
                            <TextField
                                id="japTitle"
                                label="Japanese Title"
                                margin="normal"
                                rel="japTitle"
                                fullWidth
                                multiline
                                value={japTitle}
                                onChange={(ev) => {
                                    setjapTitle(ev.target.value);
                                }}
                            />
                        </FormControl>
                    )}
                    <FormControl
                            className={classes.engTitle}
                        >
                            <TextField
                                id="engTitle"
                                label="English Title"
                                margin="normal"
                                rel="engTitle"
                                fullWidth
                                multiline
                                value={engTitle}
                                onChange={(ev) => {
                                    setEngTitle(ev.target.value);
                                }}
                            />
                    </FormControl>
                </div>
                </div>
                <Button
                    variant={"dark"}
                    onClick={createGame}
                >
                    {`Create Game`}
                </Button>
            </div>
    );
}

export default New;
