import React, { useEffect, useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Table } from 'reactstrap';
import publicIp from 'public-ip';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import Button from 'react-bootstrap/Button';
import Dialog from '@material-ui/core/Dialog/Dialog';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import NewItem from '../Item/New';
import ViewItem from '../Item/Item';

const SearchResults = ({ query, callFetch, loggedIn, isLoading }) => {
    const [ results, setResults ] = useState({
        'games': [],
        "accessories": [],
        "consoles": []
    });
    const cookie = useRef("");
    const ip = useRef("");
    const [ newItemModal, setNewItemModal ] = useState(false);
    const [ viewItemModal, setViewItemModal ] = useState(false);
    const [ viewItemId, setViewItemId ] = useState(0);
    // todo: change when we have multiple tabs and can choose different item types
    const [ itemType, setItemType ] = useState("game");

    useEffect(() => {
        (async () => {
            await publicIp.v4().then((response) => {
                const fullCookie = document.cookie;
                const cookieArray = fullCookie.split("=");
                cookie.current = cookieArray[1];
                ip.current = response;
                search(cookie.current, ip.current);
            });
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const useStyles = makeStyles(theme => ({
        header: {
            marginTop: "1rem",
            fontWeight: "bold"
        },
        info: {

        },
        root: {
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "column",
            alignItems: "center",
            fontFamily: '"Lucida Grande", "Georgia", "Arial"',
            marginBottom: "5rem"
        },
        searchResult: {
            cursor: "pointer"
        },
        findRoute: {
            marginTop: "2rem"
        }
    }));


  const classes = useStyles();


    const search = (cookie, ip) => {
        const searchQuery = `
query {
  search (query:"${query}", cookie: "${cookie}", ip: "${ip}") {
    games {
        ean
        own
        id
        eng_title
        jap_title
        region {
          title
          country
        }
        console {
          eng_title
          jap_title
        }
    }
    accessories {
        ean
        own
        id
        eng_title
        jap_title
        region {
          title
          country
        }
        console {
          eng_title
          jap_title
        }
    }
    consoles {
        ean
        own
        id
        eng_title
        jap_title
        region {
          title
          country
        }
        console {
          eng_title
          jap_title
        }
    }
  }
}
    `;

        callFetch(searchQuery, {}, (response) => {
            if (response.data.search) {
                setResults(response.data.search);
            }
        });
      };

    return (
        <div className={classes.root}>
        <Button
                variant={"dark"}
                onClick={() => {
                    setNewItemModal(true);
                }}
                className={classes.findRoute}
            >
                {`Can't find a ${itemType}?`}
            </Button>
        <Table borderless className={classes.table}>
            <tbody>
                {results.games.map((item, idx) => {
                    return (
                        <tr
                            className={classes.searchResult}
                            onClick={() => {
                                setViewItemId(item.id);
                                setViewItemModal(true);
                            }}
                            key={`search-result-${idx}`}
                        >
                            <td>
                                {item.eng_title}
                            </td>
                            <td>
                                {item.console.eng_title}
                            </td>
                            <td>
                                {item.region.title}
                            </td>
                            <td>
                                {item.own ? <CheckCircleOutlineOutlinedIcon /> : "" }
                            </td>
                        </tr>
                    );
                })}
            </tbody>
        </Table>
            <Dialog open={newItemModal} onClose={() => setNewItemModal(false)} aria-labelledby="form-dialog-title" maxWidth="md" fullWidth>
                <DialogContent>
                <NewItem
                    callFetch={callFetch}
                    listType={itemType}
                    onClose={()=> {
                        setNewItemModal(false);
                    }}
                />
                </DialogContent>
            </Dialog>
            <Dialog
                open={viewItemModal}
                onClose={() => {
                    setViewItemModal(false);
                    setViewItemId(0);
                }}
                aria-labelledby="form-dialog-title" maxWidth="md" fullWidth>
                <DialogContent>
                <ViewItem
                    listType={"games"}
                    callFetch={callFetch}
                    loggedIn={loggedIn}
                    isLoading={isLoading}
                    id={viewItemId}
                />
                </DialogContent>
            </Dialog>
        </div>
    );
}

export default SearchResults;
