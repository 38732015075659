import React from 'react';

import "./Styles/GeneralFAQs.scss";

const GeneralFAQs = () => {
        return (
            <div className="generalFAQs">
                <div className="generalFAQs__sectionTitle" id="games-barcode" >
                    <h3>General FAQs</h3>
                </div>
                <div className="question-block">
                    <a className="accordion-toggle question-title" data-toggle="collapse" data-parent="#accordion1" href="#gemeralFAQs__generalCollapseOne">  
                        What is UPC/EAN/JAN?
                    </a>
                    <div id="gemeralFAQs__generalCollapseOne" className="accordion-body collapse">  
                        <div className="accordion-inner">  
                            <p>This refers to the barcode the item is referenced by. The barcode is useful for two reasons.</p>
                            <p>First, barcodes are used in checkout automation at any store worldwide. This automated identification data capture allows us to feel very confident in the barcodes uniqueness and universality.</p>
                            <p>Second, we don't want to reinvent the wheel. Each item, and every product for that matter, has a unique barcode. A PsOne Books version and a black label version game have different barcodes, a green N64 controller has a different barcode than a red N64 controller. Utilizing this feature allows us to easily search for the game and allows us to ensure the game is unique to our database.</p>
                        </div>
                    </div>
                </div>
                <div className="question-block" id="barcode-faq">
                    <a className="accordion-toggle question-title" data-toggle="collapse" data-parent="#accordion2" href="#generalCollapseTwo">  
                        Barcode adding procedure
                    </a>
                    <div id="generalCollapseTwo" className="accordion-body collapse">  
                        <div className="accordion-inner">  
                            <p>When adding a barcode to an item please add all numbers and/or letters given in the barcode. Here are some examples of barcodes and circled is the portion of the barcode that needs to be added to the database.</p>
                            <ul className="image-list">

                                <li className="image-list-title">Leading letter example:</li>
                                <li><a href="/assets/help/leading-letter.jpg"> <img src="/assets/help/leading-letter.jpg" alt="Barcode with leading letter" height="50px" width="150px"/></a></li>

                                <li className="image-list-title">Leading 0 example:</li>
                                <li><a href="/assets/help/leading-0.jpg"><img src="/assets/help/leading-0.jpg" alt="Barcode with leading/trailing 0" height="50px" width="150px" /></a></li>

                                <li className="image-list-title">Every other example:</li>
                                <li><a href="/assets/help/normal.jpg"><img src="/assets/help/normal.jpg" alt="Every other kind of barcode" height="50px" width="150px" /></a></li>
                            </ul>

                            <p>All numbers in the barcode need to be added, this includes any leading characters and any leading/trailing 0s. This allows a game to be indexed properly, it will also benefit mobile users, as they will be able to scan their barcode and add it to their collection, an incorrect barcode means incorrect indexing.</p>

                            <p><b>tl;dr</b> Please add the whole barcode and do it right.</p>
                        </div>  
                    </div>  
                </div>
                <div className="question-block">
                    <a className="accordion-toggle question-title" data-toggle="collapse" data-parent="#accordion3" href="#generalCollapseThree">  
                        Console association  
                    </a>  
                    <div id="generalCollapseThree" className="accordion-body collapse">  
                        <div className="accordion-inner">  
                            <p>Every item is associated with a console of some kind, this also means consoles in the database. There needs to be some connection between the DK Jungle Green N64 and a first-release gray N64. We need to associate every item with a console in order to keep the database organized. This also means those who collect different consoles, accessories, and games will be able to sort them by their overarching category, i.e. consoles.</p>
                            <p>On a side note, I feel like this shouldn't have to be explained. Mistakes happen, but if this becomes a regular offense then the user will have creation privileges revoked. </p>
                            <p>In short, <span className="generalFAQs__warning">Don't screw this up</span> please.</p>
                        </div>  
                    </div>
                </div>
            </div>
        );
}

export default GeneralFAQs;