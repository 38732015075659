import React, { useEffect, useState, useRef } from 'react';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import publicIp from 'public-ip';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog/Dialog';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import NewInventoryItem from '../Inventory/New';
import ConfirmModal from '../Helpers/ConfirmModal';

const Item = ({ listType, callFetch, id, isLoading, loggedIn, onClose }) => {
    const [item, setItem] = useState({
        'region': {}
    });
    const [ inventory, setInventory ] = useState({});
    const [ modal, setModal ] = useState(false);
    const [ isAdmin, setIsAdmin ] = useState(false);
    const [ routeTrigger, setRouteTrigger ] = useState('');
    const ip = useRef('');
    const cookie = useRef('');
    const [ confirmModal, setConfirmModal ] = useState(false);

    useEffect(() => {
        (async () => {
            await publicIp.v4().then((response) => {
                const fullCookie = document.cookie;
                const cookieArray = fullCookie.split("=");
                switch (listType) {
                    case "games":
                        setRouteTrigger("Game");
                        break;
                    default:
                        setRouteTrigger("Game");
                        break;
                }
                cookie.current = cookieArray[1];
                ip.current = response;
                getItem();
                getOwnedGame(cookieArray[1], response); //todo: why is this being called so much?
                checkCookie(cookieArray[1], response);
            });
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const useStyles = makeStyles(theme => ({
        header: {
            marginTop: "1rem",
            fontWeight: "bold"
        },
        info: {
        },
        root: {
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "column",
            alignItems: "center",
            fontFamily: '"Lucida Grande", "Georgia", "Arial"'
        },
        buttons: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around"
        }
    }));


  const classes = useStyles();

    const checkCookie = (cookie, ip) => {
        const loginQuery = `
query {
  user (cookie: "${cookie}", ip: "${ip}") {
    cookie
    alias
    id
    admin
  }
}
    `;

        callFetch(loginQuery, {}, (response) => {
            if (response.data.user) {
                if (response.data.user.admin) {
                    setIsAdmin(true);
                }
            }
        });
    }

const removeFromDatabase = () => {
        const ownershipQuery = `
mutation {
  removeFromDatabase (id: ${id}, cookie: "${cookie.current}", ip: "${ip.current}") {
    status
  }
}
    `;

        callFetch(ownershipQuery, {}, (response) => {
            if (onClose) {
                onClose();
            }
        }, () => {}, true);
    }

    const getOwnedGame = (cookie, ip) => {
        const ownershipQuery = `
query {
  ownedGame (id: ${id}, cookie: "${cookie}", ip: "${ip}") {
    own
    id
  }
}
    `;

        callFetch(ownershipQuery, {}, (response) => {
            if (response.data.ownedGame) {
                setInventory(response.data.ownedGame);
            }
        }, () => {}, true);
    }

    const getItem = () => {
        const query = `
query {
  ${listType} (id:${id}) {
    ean
    id
    eng_title
    jap_title
    region {
      title
      country
    }
    console {
      eng_title
      jap_title
    }
  }
}
    `;

        callFetch(query, {}, (response) => {
            if (response.data[listType]) {
                setItem(response.data[listType][0]);
            }
        });
      };

    return (
        <div className={classes.root}>
            <h1 className={classes.header}>{item.eng_title}</h1>
            <h5 className={classes.info}>{item.jap_title}</h5>
            <h5 className={classes.info}>{item.region.title}</h5>
            <h6 className={classes.info}>{item.ean}</h6>
            {loggedIn && (
                <Container className={classes.buttons}>
                    <Row sm={8}>
                        {inventory.own ? (
                            <Button
                                variant={"dark"}
                                onClick={() => {
                                    window.location = `/inventory${routeTrigger}/${inventory.id}`
                                }}
                            >
                                {"You Own This"}
                            </Button>
                        ) :
                        (
                            <Button
                                variant={"dark"}
                                onClick={() => {
                                    setModal(true);
                                }}
                            >
                                {"Add To Collection"}
                            </Button>
                        )}
                    </Row>
                    {isAdmin && (
                    <Row sm={8}>
                        <Button
                            variant={"dark"}
                            onClick={() => {
                                setConfirmModal(true);
                            }}
                        >
                            {`Remove ${routeTrigger}`}
                        </Button>
                    </Row>
                        )}
                </Container>
            )}
            <Dialog open={modal} onClose={() => setModal(false)} aria-labelledby="form-dialog-title" maxWidth="md" fullWidth>
                <DialogContent>
                <NewInventoryItem
                    callFetch={callFetch}
                    listType={listType}
                    id={id}
                    title={item.eng_title}
                />
                </DialogContent>
            </Dialog>
            <Dialog open={confirmModal} onClose={() => setConfirmModal(false)} aria-labelledby="form-dialog-title" maxWidth="md" fullWidth>
                <DialogContent>
                <ConfirmModal
                    onCancel={() => {
                        setConfirmModal(false);
                    }}
                    title={`Are you sure you want to remove ${item.eng_title} from your collection?`}
                    itemTitle={item.eng_title}
                    onConfirm={() => {
                        // removeGameFromCollection();
                        setConfirmModal(false);
                        removeFromDatabase();
                        // onClose();
                    }}
                />
                </DialogContent>
            </Dialog>
        </div>
    );
}

export default Item;
