import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CollectionList from '../Collection/CollectionList';
import ConsoleList from '../Collection/ConsoleList';

const Collection = ({ callFetch, loggedIn, currentUser, ip, context, consoleId }) => {
    const [ collection, setCollection ] = useState([]);
    const [ consoles, setConsoles ] = useState([]);
    const [ filterConsole, setFilterConsole ] = useState("");
    const [ sort, setSort ] = useState("");
    const [ firstLoad, setFirstLoad ] = useState(true);

    useEffect(() => {
        if (firstLoad) {
            setFirstLoad(false);
            getConsoles();
        }
        getCollection();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sort, filterConsole])

    const useStyles = makeStyles(theme => ({
        root: {
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
            marginTop: "1rem"
        },
        order: {

        },
        orderBy: {
            cursor: "pointer",
            marginLeft: ".5rem",
            "&:hover": {
                fontWeight: "bold"
            }
        }
    }));


    const classes = useStyles();

    const getCollection = () => {
        const collectionQuery = `
query {
  collection (userId: ${currentUser.id}, filterConsole: "${filterConsole}", sort: "${sort}") {
        ${context} {
        id
        eng_title
        jap_title
        console {
            id
            eng_title
            jap_title
        }
        region {
            id
            title
            country
        }
      }
 }
}
    `;

        callFetch(collectionQuery, {}, (response) => {
            if (response.data.collection) {
                setCollection(response.data.collection[context]);
            }
        });
    }
    const getConsoles = () => {
        const consolesQuery = `
query {
  generalConsoles  {
        id
        eng_title
        jap_title
 }
}
    `;

        callFetch(consolesQuery, {}, (response) => {
            if (response.data.generalConsoles) {
                setConsoles(response.data.generalConsoles);
            }
        });
    }

    const orderByName = () => {
        setSort("name");
    }

    const orderByDate = () => {
        setSort("");
    }

        return (
            <div className={classes.root}>
                <div
                    className={classes.order}
                >
                {"Order by:"}
                <span
                    className={classes.orderBy}
                    onClick={orderByName}
                >
                    {"Name"}
                </span>
                <span
                    className={classes.orderBy}
                    onClick={orderByDate}
                >
                    {"Date Added"}
                </span>
                </div>
                <CollectionList
                    items={collection}
                    context={context}
                    setFilterConsole={setFilterConsole}
                    setSort={setSort}
                    callFetch={callFetch}
                    currentUser={currentUser}
                />
                <ConsoleList
                    items={consoles}
                    context={context}
                    setFilterConsole={setFilterConsole}
                    setSort={setSort}
                />
            </div>
        );
    }

export default Collection;
