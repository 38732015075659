import React from 'react';

import "./Styles/ConsoleFAQs.scss";

const ConsoleFAQs = () => {
        return (
            <div className="consoleFAQs">
                <div className="consoleFAQs__sectionTitle">
                    <h3>Console FAQs</h3>
                </div>

                <div className="question-block">
                    <a className="accordion-toggle question-title" data-toggle="collapse" data-parent="#accordion1" href="#collapseOne">
                        Cosmetic Condition
                    </a>
                </div>
            </div>
        );
}

export default ConsoleFAQs;