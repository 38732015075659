import React, { useEffect, useState } from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import NewItem from '../Item/New';
import ViewItem from '../Item/Item';

const Browse = ({ listType, callFetch, loggedIn, isLoading }) => {

    const [itemList, setItemList] = useState([]);
    const [consoleList, setConsoleList] = useState([]);
    const [ routeTrigger, setRouteTrigger ] = useState('');
    const [ modal, setModal ] = useState(false);
    const [ viewItemModal, setViewItemModal ] = useState(false);
    const [ viewItemId, setViewItemId ] = useState(0);
    const [ selectedConsole, setSelectedConsole ] = useState(0);

    useEffect(() => {
        getConsoleList();
        switch (listType) {
            case "games":
                setRouteTrigger("Game");
                break;
            default:
                setRouteTrigger("Game");
                break;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const useStyles = makeStyles(theme => ({
        backButton: {
            cursor: "pointer",
            marginBottom: "1rem",
            maxWidth: "12rem",
            marginLeft: "auto",
            marginRight: "auto",
        },
        findRoute: {
            marginBottom: "1rem",
            maxWidth: "12rem",
            marginLeft: "auto",
            marginRight: "auto",
        },
        root: {
            display: "flex",
            flexWrap: "wrap",
            textAlign: "center",
            flexDirection: "column",
            marginBottom: "5rem"
        }
    }));


  const classes = useStyles();

    const getConsoleList = () => {
        const query = `
query {
  generalConsoles {
    eng_title
    jap_title
    id
  }
}
    `;

        callFetch(query, {}, (response) => {
            if (response.data.generalConsoles) {
                setConsoleList(response.data.generalConsoles);
            }
        });
      };


    const getItemList = (consoleId) => {
        const query = `
query {
  ${listType} (console:${consoleId}) {
    ean
    id
    eng_title
    jap_title
    region {
      title
      country
    }
    console {
      eng_title
      jap_title
    }
  }
}
    `;

        callFetch(query, {}, (response) => {
            if (response.data[listType]) {
                setItemList(response.data[listType]);
            }
        });
      };

    return (
        <div className={classes.root}>
            <h1>{`All ${listType.charAt(0).toUpperCase() + listType.slice(1)}`}</h1>
            <Button
                variant={"dark"}
                onClick={() => {
                    setModal(true);
                }}
                className={classes.findRoute}
            >
                {`Can't find a ${routeTrigger}?`}
            </Button>
            {itemList.length > 0 ? 
                <Button
                    variant={"info"}
                    className={classes.backButton}
                    onClick={() => {
                        setItemList([])
                    }}
                >
                    {"Back"}
                </Button> : null}
            <Container>
            <Row sm={2}>
            {itemList.map((item, idx) => {
                return (
                    <ListGroup.Item
                        key={`game-list-${idx}`}
                        action
                        onClick={() => {
                            setViewItemModal(true);
                            setViewItemId(item.id);
                        }}
                    >
                        {`${item.eng_title}`}
                    </ListGroup.Item>
                );
            })}
            {itemList.length <= 0 ? consoleList.map((item, idx) => {
                return (
                    <ListGroup.Item
                        key={`console-list-${idx}`}
                        action
                        onClick={() => {
                            getItemList(consoleList[idx].id);
                            setSelectedConsole(consoleList[idx].id);
                        }}
                    >
                        {`${item.eng_title} ${listType}`}
                    </ListGroup.Item>
                );
            }) : null}
            </Row>
            </Container>
            <Dialog open={modal} onClose={() => setModal(false)} aria-labelledby="form-dialog-title" maxWidth="md" fullWidth>
            <DialogTitle id="form-dialog-title">{"Add a new game"}</DialogTitle>
                <DialogContent>
                    <NewItem
                        callFetch={callFetch}
                        listType={listType}
                        onClose={() => {
                            setModal(false);
                            getItemList(selectedConsole);
                        }}
                        defaultConsole={selectedConsole}
                    />
                </DialogContent>
            </Dialog>
            <Dialog
                open={viewItemModal}
                onClose={() => {
                    setViewItemModal(false);
                    setViewItemId(0);
                }}
                aria-labelledby="form-dialog-title" maxWidth="md" fullWidth>
                <DialogContent>
                <ViewItem
                    listType={"games"}
                    callFetch={callFetch}
                    loggedIn={loggedIn}
                    isLoading={isLoading}
                    id={viewItemId}
                    onClose={() => {
                        getItemList(selectedConsole);
                        setViewItemModal(false);
                        setViewItemId(0);
                    }}
                />
                </DialogContent>
            </Dialog>
        </div>
    );
}

export default Browse;
