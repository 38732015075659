import React, { Component } from 'react';

import "./Styles/Dropdown.scss";

class Dropdown extends Component {
    state = {
        open: false
    }
    toggleDropdown() {
        console.log("open", this.state.open);
        this.setState({ open: !this.state.open });
    }
    render() {
        // TODO: Finish this lol
        return (
            <div
                onClick={this.toggleDropdown}
            >
                {this.props.children}
            </div>
        )
    }
}

export default Dropdown;