import React from 'react';
import ReactDOM from 'react-dom';
import App from './App/App';
// import reportWebVitals from './reportWebVitals';

const title = "Hello World!";

ReactDOM.render(
  <App
  	title={title}
  />,
  document.getElementById('root')
);

module.hot.accept();