import React, { useEffect, useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from 'react-bootstrap/Button';
import publicIp from 'public-ip';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';

const New = ({ callFetch, id, listType, title, editItem, onClose }) => {
    const cookie = useRef("");
    const ip = useRef("");
    const [ item, setItem ] = useState({
        region: {}
    });
    const [ qualityList, setQualityList ] = useState([]);
    const [ selectedQuality, setSelectedQuality ] = useState({
        game: 1,
        manual: 1,
        inserts: 1,
        box: 1,
        spineCard: 6
    });
    const [ notes, setNotes ] = useState("");
    const [ complete, setComplete ] = useState(false);

    useEffect(() => {
        if (!editItem) {
            getItem();
        } else {
            setItem(editItem);
            setComplete(editItem.complete === 1 ? true : false);
            setNotes(editItem.notes);
            setSelectedQuality({
                'box': getQualityIdByName(editItem.box_condition),
                'game': getQualityIdByName(editItem.game_condition),
                'inserts': getQualityIdByName(editItem.inserts_condition),
                'manual': getQualityIdByName(editItem.manual_condition),
                'spineCard': getQualityIdByName(editItem.spine_card_condition),
            })
        }
        if (qualityList.length <= 0) {
            getQualityList();
        }
        (async () => {
            await publicIp.v4().then((response) => {
                const fullCookie = document.cookie;
                const cookieArray = fullCookie.split("=");
                cookie.current = cookieArray[1];
                ip.current = response
            });
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [qualityList])


    const getQualityList = () => {
        const query = `
query {
  qualityList {
    id
    quality
  }
 }

    `;

        callFetch(query, {}, (response) => {
            if (response.data.qualityList) {
                setQualityList(response.data.qualityList);
            }
        });
      };

    const updateOwnership = () => {
        const query = `
mutation {
  updateCollection (id: ${id}, gameCondition: ${selectedQuality['game']}, manualCondition: ${selectedQuality['manual']}, insertsCondition: ${selectedQuality['inserts']}, boxCondition: ${selectedQuality['box']}, spineCardCondition: ${selectedQuality['spineCard']}, complete: ${complete}, notes: "${notes}", cookie:"${cookie.current}", ip:"${ip.current}") {
    status
  }
}
    `;

        callFetch(query, {}, (response) => {
            onClose();
        });
      };

    const addToCollection = () => {

        const query = `
mutation {
  addToCollection (gameId: ${id}, gameCondition: ${selectedQuality['game']}, manualCondition: ${selectedQuality['manual']}, insertsCondition: ${selectedQuality['inserts']}, boxCondition: ${selectedQuality['box']}, spineCardCondition: ${selectedQuality['spineCard']}, complete: ${complete}, notes: "${notes}", cookie:"${cookie.current}", ip:"${ip.current}") {
    status
  }
}
    `;

        callFetch(query, {}, (response) => {
            // TODO: potentially return the id instead of the status so we can go straight to the game, or maybe just the collection is fine
            window.location = "/gameCollection";
        });
      };

    const useStyles = makeStyles(theme => ({
        root: {
            alignItems: "center",
            textAlign: "center",
        },
        form: {
        },
        gameTitle: {
            textAlign: 'center',
            fontFamily: '"Lucida Grande", "Georgia", "Arial"'
        },
        newTooltip: {
            marginLeft: '5px',
            '&:hover': {
                '&:before': {
                    position: 'absolute',
                    content: 'attr(tooltip)',
                    display: 'inline-block',
                    marginLeft: '20px',
                    background: 'none repeat scroll #feffcd',
                    border: 'solid 1px black',
                    '-moz-border-radius': '10px',
                    '-webkit-border-radius': '10px',
                    borderRadius: '10px',
                    color: '#000',
                    padding: '10px 10px',
                    maxWidth: '200px',
                },
                '&:after': {
                    content: "",
                    borderColor: '#f3961c',
                    borderWidth: '10px 50px 10px 0',
                    bottom: 'auto',
                    left: '-50px',
                    top: '16px',
                }
            }
        },
        newBody: {
            marginTop: '100px'
        },
        quality: {
            marginTop: "1rem"
        },
        qualityList: {
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
            '& div': {
                paddingRight: ".25rem",
                paddingLeft: ".25rem",
                minWidth: "7rem"
            }
        },
        title: {
            marginTop: "2rem",
            marginBottom: "1rem"
        },
        notes: {
            display: "flex",
            flexDirection: "column"
        },
        checkbox: {
            display: "flex",
            alignSelf: "center",
            marginTop: "1rem",
            marginBottom: "1rem"
        }
    }));


    const classes = useStyles();


    const getItem = () => {
        const query = `
query {
  ${listType} (id:${id}) {
    ean
    id
    eng_title
    jap_title
    spine_card_exists
    region {
      title
      country
    }
    console {
      eng_title
      jap_title
    }
  }
}
    `;

        callFetch(query, {}, (response) => {
            if (response.data[listType]) {
                setItem(response.data[listType][0]);
                if (response.data[listType][0]['spine_card_exists']) {
                    setSelectedQuality({ ...selectedQuality, [`spineCard`]: 1 });
                }
            }
        });
      };

      const handleChangequality = (event, itemName) => {
        setSelectedQuality({ ...selectedQuality, [`${itemName}`]: event.target.value });
      }

      const getQualityIdByName = (name) => {
        let id = 0;
        qualityList.some((listItem) => {
            if (listItem.quality.toLowerCase() === name.toLowerCase()) {
                id = listItem.id;
                return true;
            }
        });

        return id;
      }

    return (
            <div className={classes.root}>
                <FormControl
                    className={classes.formControl}
                >
                    <h3 className={classes.title}>{"Physical Quality"}</h3>
                </FormControl>
                <div
                    className={classes.qualityList}
                >
                    <FormControl
                        className={classes.quality}
                    >
                            <FormHelperText id={"game-quality-helper"}>{"Game Condition"}</FormHelperText>
                            <Select
                                defaultValue={selectedQuality['game']}
                              value={selectedQuality['game']}
                              onChange={(event) => {
                                handleChangequality(event, "game")
                                }}
                            >
                              {qualityList.map((value) => {
                                    return <MenuItem key={`quality-${value.id}`} value={value.id}>{`${value.quality}`}</MenuItem>;
                              })}
                            </Select>
                      </FormControl>
                      <FormControl
                        className={classes.quality}
                    >
                            <FormHelperText id={"game-quality-helper"}>{"Manual Condition"}</FormHelperText>
                            <Select
                                defaultValue={selectedQuality['manual']}
                              value={selectedQuality['manual']}
                              onChange={(event) => {
                                handleChangequality(event, "manual")
                                }}
                            >
                              {qualityList.map((value) => {
                                    return <MenuItem key={`game-quality-${value.id}`} value={value.id}>{`${value.quality}`}</MenuItem>;
                              })}
                            </Select>
                      </FormControl>
                      <FormControl
                        className={classes.quality}
                    >
                            <FormHelperText id={"manual-quality-helper"}>{"Inserts Condition"}</FormHelperText>
                            <Select
                                defaultValue={selectedQuality['inserts']}
                              value={selectedQuality['inserts']}
                              onChange={(event) => {
                                handleChangequality(event, "inserts")
                                }}
                            >
                              {qualityList.map((value) => {
                                    return <MenuItem key={`manual-quality-${value.id}`} value={value.id}>{`${value.quality}`}</MenuItem>;
                              })}
                            </Select>
                      </FormControl>
                      {item.spine_card_exists && (
                        <FormControl
                            className={classes.quality}
                        >
                            <FormHelperText id={"quality-helper"}>{"Spine Card Condition"}</FormHelperText>
                            <Select
                                defaultValue={selectedQuality['spineCard']}
                                value={selectedQuality['spineCard']}
                                onChange={(event) => {
                                    handleChangequality(event, "spineCard")
                                }}
                            >
                              {qualityList.map((value) => {
                                    return <MenuItem key={`quality-${value.id}`} value={value.id}>{`${value.quality}`}</MenuItem>;
                              })}
                            </Select>
                        </FormControl>
                      )}
                      <FormControl
                        className={classes.quality}
                    >
                            <FormHelperText id={"quality-helper"}>{"Box Condition"}</FormHelperText>
                            <Select
                                defaultValue={selectedQuality['box']}
                                value={selectedQuality['box']}
                                onChange={(event) => {
                                    handleChangequality(event, "box")
                                }}
                            >
                              {qualityList.map((value) => {
                                    return <MenuItem key={`quality-${value.id}`} value={value.id}>{`${value.quality}`}</MenuItem>;
                              })}
                            </Select>
                      </FormControl>
                </div>
                <div
                    className={classes.notes}
                >
                    <h3 className={classes.title}>{"Notes"}</h3>
                    <FormControl
                        className={classes.quality}
                    >
                        <TextField
                            id="notes"
                            label="Notes"
                            margin="normal"
                            rel="notes"
                            fullWidth
                            multiline
                            rows={5}
                            value={notes}
                            onChange={(ev) => {
                                setNotes(ev.target.value);
                            }}
                        />
                    </FormControl>

                    <FormControl
                        className={classes.checkbox}
                    >
                        <FormControlLabel control={
                            <Checkbox 
                                color="primary"
                                onChange={() => {
                                    setComplete(!complete);
                                }}
                                checked={complete}
                            />} 
                                label="Complete"
                                labelPlacement="start"
                            />
                    </FormControl>
                    {editItem ? (
                        <Button
                            variant={"dark"}
                            onClick={updateOwnership}
                        >
                            {`Save`}
                        </Button>) : (
                        <Button
                            variant={"dark"}
                            onClick={addToCollection}
                        >
                            {`Add ${title} To Your Collection`}
                        </Button>
                    )}
                </div>
            </div>
    );
}

export default New;
