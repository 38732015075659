import React from 'react';
//style="font-family: arial"
import { ContainerDiv } from './Styles/Components/Base';

import "./Styles/BasePage.scss";

const About = () => {
	return (
	    <ContainerDiv className="aboutContainer">
	    <h1 id="oougHeader">{"OOUG"}</h1>
			<div id="About" className="textcenter">
				<h3>{"About This Project"}</h3>
			</div>
			<p className="indent">
				{"We are creating this website to allow visitors who enjoy games to see games they've never heard of.There are games that exist that gamers in our part of the world have never heard of. For example: "}
			 	<span><em>{"Clock tower"}</em>{", "}<em>{"Reiselied"}</em></span>{", etc. These games are wonderful, but have a tiny audience. We hope to share the existence of these games. We hope you will be able to find something new from this website and grow further in your search for entertaining games."}
		    </p>

			<div className="textcenter" id="About">
				<h3>{"More Specifically"}</h3>
			</div>
			<p className="indent">
				{"This site is partially an encycopedia and partially an inventory, we plan to include as much unknown information about the games on this site, even if the game is known. There is a lot of information that is lost in translation. We desire to bring this information into view. Information like this is not known by the common public, this is what we want people to gain from this site."}
		    </p>
		</ContainerDiv>
	);
}

export default About;
