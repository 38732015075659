import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

const Profile = ({ callFetch, loggedIn, currentUser, ip, isLoading }) => {
    // isADMIN
    const [ isCurrentUser, setIsCurrentUser ] = useState(false);
    const [ inventoryCount, setInventoryCount ] = useState(0);
    const [ firstLoad, setFirstLoad ] = useState(true);

    useEffect(() => {
        checkCookie(currentUser.cookie);
        getGameCount();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getGameCount = () => {
        const gameCountQuery = `
query {
  inventoryCount (userId: ${currentUser.id}) {
    gameCount
    accessoryCount
    consoleCount
  }
}
    `;

        callFetch(gameCountQuery, {}, (response) => {
            if (response.data.inventoryCount) {
                setInventoryCount(response.data.inventoryCount);
                setFirstLoad(false);
            }
        }, () => {}, true);

    }

    const checkCookie = (cookie) => {
        const loginQuery = `
query {
  user (cookie: "${cookie}", ip: "${ip}") {
    cookie
    alias
    id
    admin
  }
}
    `;

        callFetch(loginQuery, {}, (response) => {
            if (response.data.user) {
                const cookie = response.data.user.cookie;
                if (cookie) {
                    setIsCurrentUser(true);
                }
            }
        });
    }

    const useStyles = makeStyles(theme => ({
        root: {
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
            marginTop: "1rem"
        },
        counts: {
            display: "flex",
            flexDirection: "column"
        },
        currentUser: {
            marginBottom: "1rem"
        },
        collection: {
            cursor: "pointer",
            "&:hover": {
                fontWeight: "bold"
            }
        }
    }));


  const classes = useStyles();


        return (
            <div className={classes.root}>
            { (isLoading.current && !inventoryCount) || firstLoad ? (
                <div>
                    {"LOADING"}
                </div>
            ) : isCurrentUser ? (
                    <div>
                        <h4
                            className={classes.currentUser}
                        >
                            {`Hello ${currentUser.alias}`}
                        </h4>
                        <div
                            className={classes.counts}
                        >
                            <div>
                                {"Number of Games in your collection: "}
                                <span
                                    className={classes.collection}
                                    onClick={() => {
                                        window.location = "/gameCollection";
                                    }}
                                >
                                    {`${inventoryCount.gameCount}`}
                                </span>
                            </div>
                            <div>
                                {"Number of Accessories in your collection: "}
                                <span
                                    className={classes.collection}
                                    onClick={() => {
                                        window.location = "/accessoryCollection";
                                    }}
                                >
                                    {`${inventoryCount.accessoryCount}`}
                                </span>
                            </div>
                            <div>
                            {`Number of Consoles in your collection: ${inventoryCount.consoleCount}`}
                            </div>
                        </div>
                    </div>
                ) : (
                    <div>
                    {"Loading"}
                    </div>
                )
                }
            </div>
        );
    }

export default Profile;
