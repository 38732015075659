import React, { useEffect, useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import publicIp from 'public-ip';
import EditInventoryItem from './New';
import Dialog from '@material-ui/core/Dialog/Dialog';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import ConfirmModal from '../Helpers/ConfirmModal';

const Item = ({ callFetch, loggedIn, currentUser, id, listType, onClose }) => {
    const [ ownedItem, setOwnedItem ] = useState({
        region: {},
        item: {}
    });

    const cookie = useRef("");
    const ip = useRef("");
    const [editModal, setEditModal] = useState(false);
    const [confirmModal, setConfirmModal] = useState(false);

    useEffect(() => {
        (async () => {
            await publicIp.v4().then((response) => {
                const fullCookie = document.cookie;
                const cookieArray = fullCookie.split("=");
                cookie.current = cookieArray[1];
                ip.current = response;
                getOwnedGame(cookieArray[1], response);
            });
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const useStyles = makeStyles(theme => ({
        header: {
            marginTop: "1rem",
            fontWeight: "bold"
        },
        info: {

        },
        root: {
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "column",
            alignItems: "center",
            fontFamily: '"Lucida Grande", "Georgia", "Arial"',
            backgroundColor: "white",
            paddingBottom: "1rem",
            justifyContent: "center"
        },
        gameTitle: {
            textAlign: "center"
        },
        qualities: {
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column"
        },
        manageButtons: {
            display: "flex",
            justifyContent: "space-between"
        }
    }));


    const classes = useStyles();

    const getOwnedGame = (cookie, ip) => {
        const ownershipQuery = `
query {
  inventory (id: ${id}, cookie: "${cookie}", ip: "${ip}") {
    id
    own
    complete
    box_condition
    game_condition
    manual_condition
    inserts_condition
    notes
    spine_card_condition
    spine_card_exists
    count
    item {
      id
      ean
      eng_title
      jap_title
    }
    console {
      eng_title
      jap_title
    }
    region {
      title
      country
    }
  }
}
    `;

        callFetch(ownershipQuery, {}, (response) => {
            if (response.data.inventory) {
                setOwnedItem(response.data.inventory);
            }
        });
    }

    const removeGameFromCollection = () => {
        const ownershipQuery = `
mutation {
  removeFromCollection (gameId: ${id}, cookie:"${cookie.current}", ip:"${ip.current}") {
    status
  }
}
    `;

        callFetch(ownershipQuery, {}, (response) => {
            setEditModal(false);
        });
    }

        return (
            <div className={classes.root}>
            <h1 className={classes.header}>{ownedItem.item.eng_title}</h1>
            <h5 className={classes.info}>{ownedItem.item.jap_title}</h5>
            <h5 className={classes.info}>{ownedItem.region.title}</h5>
            <h6 className={classes.info}>{ownedItem.item.ean}</h6>
            <div>
                {ownedItem.notes}
            </div>

            <div className={classes.qualities}>
                <div>
                    {`Game Condition: ${ownedItem.game_condition}`}
                </div>
                <div>
                    {`Manual Condition: ${ownedItem.manual_condition}`}
                </div>
                <div>
                    {`Inserts Condition: ${ownedItem.inserts_condition}`}
                </div>
                <div>
                    {`Box Condition: ${ownedItem.box_condition}`}
                </div>
                {ownedItem.spine_card_exists && (
                    <div>
                    {`Spine Card Condition: ${ownedItem.spine_card_condition}`}
                    </div>
                )}
            </div>
            <h5 className={classes.gameTitle}>{`You marked this game as ${ownedItem.complete ? "complete" : "incomplete"}`}</h5>

            <Container className={classes.manageButtons}>
                <Row sm={8}>
                    <Button
                        variant={"dark"}
                        className={classes.backButton}
                        onClick={() => {
                            setEditModal(true);
                        }}
                    >
                        {"Edit Game"}
                    </Button>
                </Row>
                <Row sm={8}>
                    <Button
                        variant={"dark"}
                        className={classes.backButton}
                        onClick={() => {
                            setConfirmModal(true);
                        }}
                    >
                        {"Remove From Collection"}
                    </Button>
                </Row>
            </Container>

            <Dialog open={editModal} onClose={() => setEditModal(false)} aria-labelledby="form-dialog-title" maxWidth="md" fullWidth>
                <DialogContent>
                <EditInventoryItem
                    callFetch={callFetch}
                    listType={listType}
                    id={id}
                    title={ownedItem.eng_title}
                    editItem={ownedItem}
                    onClose={() => {
                        setEditModal(false);
                        getOwnedGame(cookie.current, ip.current);
                    }}
                />
                </DialogContent>
            </Dialog>

            <Dialog open={confirmModal} onClose={() => setConfirmModal(false)} aria-labelledby="form-dialog-title" maxWidth="md" fullWidth>
                <DialogContent>
                <ConfirmModal
                    onCancel={() => {
                        setConfirmModal(false);
                    }}
                    title={`Are you sure you want to remove ${ownedItem.item.eng_title} from your collection?`}
                    itemTitle={ownedItem.item.eng_title}
                    onConfirm={() => {
                        removeGameFromCollection();
                        setConfirmModal(false);
                        onClose();
                    }}
                />
                </DialogContent>
            </Dialog>
            </div>
        );
    }

export default Item;
