import React, { useState } from 'react';
import { ContainerDiv } from '../Styles/Components/Base';

import "./Styles/SignUp.scss";

const SignUp = ({ loading, signUpButtonMessage = "Create My Account", actions }) => {

    const [ email, setEmail ] = useState('');
    const [ alias, setAlias ] = useState('');
    const [ password, setPassword ] = useState('');
    const [ confirmPassword, setConfirmPassword ] = useState('');

    const signup = (ev) => {
        ev.preventDefault();
        console.log("Submit!!!", email, alias, password, confirmPassword);
        // this.props.actions.authenticate(email, password);*/
    }

    return (
        <ContainerDiv className="signUpContainer">
            <h1 className="signUpContainer__head">Sign Up</h1>
            <div
                className="signUpContainer__signUp-form"
                method="post"
            >
                <div>
                    {"Alias"}
                    <div>
                        <input
                            className="signUpContainer__signUp-form_email"
                            name="alias"
                            placeholder="you will be known by this name"
                            onChange={(ev) => { setAlias(ev.target.value) }}
                            type="text"
                        />
                    </div>
                </div>
                <div>
                    {"Email"}
                    <div>
                        <input
                            className="signUpContainer__signUp-form_email"
                            name="email"
                            placeholder="email"
                            onChange={(ev) => { setEmail(ev.target.value) }}
                            type="text"
                        />
                    </div>
                </div>
                <div>
                    {"Password"}
                    <div>
                        <input
                            className="signUpContainer__signUp-form_password"
                            name="password"
                            placeholder="min of 8 characters please"
                            onChange={(ev) => { setPassword(ev.target.value) }}
                            type="password"
                        />
                    </div>
                </div>
                <div>
                    {"Confirm Password"}
                    <div>
                        <input
                            className="signUpContainer__signUp-form_password"
                            name="confirmPass"
                            placeholder="same as above"
                            onChange={(ev) => { setConfirmPassword(ev.target.value) }}
                            type="password"
                        />
                    </div>
                </div>
                <button
                    className="signUpContainer__signUp-form_submit"
                    disabled={loading}
                    onClick={signup}
                >
                    {signUpButtonMessage}
                </button>
            </div>
        </ContainerDiv>
    );
}

export default SignUp;