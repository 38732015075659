import React from 'react';
// import { Route, Link } from 'react-router-dom';
import { ContainerDiv } from './Styles/Components/Base';

import "./Styles/BasePage.scss";

const Contact = () => {
        return (
            <ContainerDiv className="contactContainer">
                <h1>{"Contact"}</h1>
                <h4>{"By Email"}</h4>
                <p>{"Any questions about games or this amazing website can be sent to "}
                    <a href="mailto:spenser.roark@gmail.com">{"spenser.roark@gmail.com"}</a>.
                </p>
            </ContainerDiv>
        )
    }

export default Contact;
