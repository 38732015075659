import React from 'react';
import { ContainerDiv } from './Styles/Components/Base';
import GeneralFAQs from './Help/GeneralFAQs';
import GameFAQs from './Help/GameFAQs';
import AccessoryFAQs from './Help/AccessoryFAQs';
import ConsoleFAQs from './Help/ConsoleFAQs';
import Dropdown from './Dropdown/Dropdown';

import "./Styles/BasePage.scss";

const Help = () => {
        return (
            <ContainerDiv className="helpContainer">
                <Dropdown>
                    <GeneralFAQs />
                </Dropdown>
                <GameFAQs />
                <AccessoryFAQs />
                <ConsoleFAQs />
            </ContainerDiv>
        );
    }

export default Help;
