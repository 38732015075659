import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog/Dialog';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import InventoryItem from '../Inventory/Item';

const CollectionList = ({ items, context, setFilterConsole, setSort, callFetch, currentUser }) => {
    const [ modal, setModal ] = useState(false);
    const [ selectedItem, setSelectedItem ] = useState(0);

    const useStyles = makeStyles(theme => ({
        root: {
            marginBottom: "5rem",
            marginTop: "3rem",
            marginLeft: "auto",
            marginRight: "auto"
        },
        link: {
            cursor: "pointer",
        },
        itemTable: {
            display: "table",
            textAlign: "left"
        },
        itemTitle: {
            display: "table-cell",
            paddingRight: "2rem",
            "&:hover": {
                cursor: "pointer",
                fontWeight: "bold"
            }
        },
        itemConsole: {
            display: "table-cell",
            paddingRight: "2rem",
            "&:hover": {
                cursor: "pointer",
                fontWeight: "bold"
            }
        },
        itemRegion: {
            display: "table-cell",
            "&:hover": {
                fontWeight: "bold",
                cursor: "pointer",
            }
        },
        itemRow: {
            display: "table-row",
            height: "3.125rem",
        }
    }));

    const capitalize = (word) => {
        return word.charAt(0).toUpperCase() + word.slice(1);
    }

    const truncate = (string, length = 33) => {
        return string.substring(0, length) + (string.length > 33 ? "..." : "");
    }

    const classes = useStyles();

    const collectionNode = () => {
        return items.map((item, idx) => {
            return (
                <div
                    className={classes.itemRow}
                    key={`inventory-game-${idx}`}
                >
                    <div
                        className={classes.itemTitle}
                        onClick={() => {
                            setModal(true);
                            setSelectedItem(item.id);
                        }}
                    >
                        {truncate(item.eng_title)}
                    </div>
                    <div
                        className={classes.itemConsole}
                        onClick={() => {
                            setFilterConsole(item.console.id);
                        }}
                    >
                        {item.console.eng_title}
                    </div>
                    <div
                        className={classes.itemRegion}
                    >
                        {item.region.title}
                    </div>
                </div>
            );
        })
    }

        return (
            <div
                className={classes.root}
            >
            {items.length <= 0 && (
                <div
                >
                    {"No games added yet?"}
                    <span
                        className={classes.link}
                        onClick={() => {
                            window.location = `/browse${capitalize(context)}`;
                        }}
                    >
                    {` Browse The ${capitalize(context)} Collection `}
                    </span>
                    {"and add some games!"}
                </div>
            )}
                <div
                    className={classes.itemTable}
                >
                {collectionNode()}
                </div>
                <Dialog
                    open={modal}
                    onClose={() => {
                        setModal(false)
                    }}
                    aria-labelledby="form-dialog-title"
                    maxWidth="md"
                    fullWidth
                >
                    <DialogContent>
                        <InventoryItem
                            callFetch={callFetch}
                            currentUser={currentUser}
                            listType={context}
                            id={selectedItem}
                            onClose={() => {
                                setModal(false);
                                window.location = '/gameCollection';
                            }}
                        />
                    </DialogContent>
                </Dialog>
            </div>
        );
    }

export default CollectionList;
