import React from 'react';

import "./Styles/AccessoryFAQs.scss";

const AccessoryFAQs = () => {
        return (
            <div className="accessoryFAQs">
                <div className="accessoryFAQs__sectionTitle">
                    <h3>Accessory FAQs</h3>
                </div>

                <div className="question-block">
                    <a className="accordion-toggle question-title" data-toggle="collapse" data-parent="#accordion1" href="#accCollapseOne">
                        Cosmetic Condition
                    </a>

                    <div id="accCollapseOne" className="accordion-body collapse">  
                        <div className="accordion-inner">
                        </div>
                    </div>
                </div>
            </div>
        );
}

export default AccessoryFAQs;