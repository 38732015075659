import React, { useEffect, useState } from 'react';
import Navbar from 'react-bootstrap/Navbar'
import NavDropdown from 'react-bootstrap/NavDropdown'
import Nav from 'react-bootstrap/Nav'
import { makeStyles } from '@material-ui/core/styles';
import publicIp from 'public-ip';

import './Styles/Header.scss';

const Header = ({ loggedIn, isAdmin, setLoggedIn, callFetch, setIsAdmin, checkLoggedIn }) => {
    const [ query, setQuery ] = useState('');


    useEffect(() => {
        (async () => {
            await publicIp.v4().then((response) => {
                const fullCookie = document.cookie;
                const cookieArray = fullCookie.split("=");
                checkLoggedIn(cookieArray[1], response);
            });
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const useStyles = makeStyles(theme => ({
        accountDropdown: {
        },
        root: {
            display: "flex",
            justifyContent: "space-around"
        },
        navbar: {
            width: "100%",
            justifyContent: "space-around"
        },
        searchBar: {
            borderRadius: "15px",
            border: "1px solid #ccc",
            paddingLeft: "2rem",
            background: "#ccc"
        },
        search: {
        }
    }));

    const logout = () => {
        document.cookie = "ooug=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; SameSite=Strict";
        localStorage.clear();
        setLoggedIn(false);
        window.location = "/";
    }

    const navigate = (location) => {
        window.location = location;
    }

    const search = (e) => {
        if (e.key === "Enter") {
            window.location = `/search/${query}`;
        }
    }

  const classes = useStyles();

        return (
            <div
                className={classes.root}
            >
                <Navbar className={classes.navbar} variant="dark" bg="dark" expand="lg">
                    <Navbar.Brand href="/">{"OOUG"}</Navbar.Brand>
                    <div>
                        <Nav className={"justify-content-start"} >
                            <Nav.Link
                                onClick={() => {
                                    navigate("/about")
                                }}
                            >
                                {"About us"}
                            </Nav.Link>
                            <Nav.Link
                                onClick={() => {
                                    navigate("/help")
                                }}
                            >
                                {"Help"}
                            </Nav.Link>
                            <Nav.Link
                                onClick={() => {
                                    navigate("/contact")
                                }}
                            >
                                {"Contact"}
                            </Nav.Link>
                            <Nav.Link
                                href="http://wiki.ooug.net"
                                target="_blank"
                            >
                                {"Wiki"}
                            </Nav.Link>
                            <NavDropdown
                                title="Browse"
                                id="collapsible-nav-dropdown"
                            >
                                <NavDropdown.Item
                                    onClick={() => {
                                        navigate("/browseGames")
                                    }}
                                >
                                    {"Browse Games"}
                                </NavDropdown.Item>
                                <NavDropdown.Item
                                    onClick={() => {
                                        navigate("/browseConsoles")
                                    }}
                                >
                                    {"Browse Consoles"}
                                </NavDropdown.Item>
                                <NavDropdown.Item
                                    onClick={() => {
                                        navigate("/browseAccessories")
                                    }}
                                >
                                    {"Browse Accessories"}
                                </NavDropdown.Item>
                            </NavDropdown>
                            {isAdmin && (
                                <NavDropdown title="Admin" id="collapsible-nav-dropdown">
                                    <NavDropdown.Item
                                        onClick={() => {
                                            navigate("/regions")
                                        }}
                                    >
                                        {"Region"}
                                    </NavDropdown.Item>
                                    <NavDropdown.Item
                                        onClick={() => {
                                            navigate("/generalConsoles")
                                        }}
                                    >
                                        {"General Consoles"}
                                    </NavDropdown.Item>
                                    <NavDropdown.Item
                                        onClick={() => {
                                            navigate("/generalStatistics")
                                        }}
                                    >
                                        {"General Statistics"}
                                    </NavDropdown.Item>
                                </NavDropdown>
                            )}
                        </Nav>
                    </div>
                    <div>
                        {!loggedIn ? (
                            <Nav>
                                <Nav.Link
                                    onClick={() => {
                                        window.location = "/login";
                                    }}
                                >
                                    {"Sign in"}
                                </Nav.Link>
                                <Nav.Link
                                    onClick={() => {
                                        window.location = "/signup";
                                    }}
                                >
                                    {"Sign up"}
                                </Nav.Link>
                            </Nav>
                        ) : (
                            <Nav
                                className={classes.accountDropdown}
                            >
                                <NavDropdown
                                    title="Account"
                                    id="collapsible-nav-dropdown"
                                >
                                    <NavDropdown.Item
                                        onClick={() => {
                                            window.location = `/profile`;
                                        }}
                                    >
                                        {"Profile"}
                                    </NavDropdown.Item>
                                    <NavDropdown.Item
                                        onClick={logout}
                                    >
                                        {"Logout"}
                                    </NavDropdown.Item>
                                </NavDropdown>
                            </Nav>
                        )}
                    </div>
                    <div className={classes.search}>
                    <input
                        type={"text"}
                        onChange={(e) => {
                            setQuery(e.target.value)
                        }}
                        placeholder={"Search for a game"}
                        className={classes.searchBar}
                        onKeyDown={search}
                    />
                </div>
                </Navbar>
            </div>
        );
    }

export default Header;
