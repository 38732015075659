import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const ConsoleList = ({ items, context, setFilterConsole, setSort }) => {
    const useStyles = makeStyles(theme => ({
        root: {
            position: "absolute",
            right: "0",
            backgroundColor: "black",
            color: "#fff",
            padding: "1rem",
            marginRight: "1rem",
        },
        console: {
            cursor: "pointer",
            paddingBottom: ".3rem"
        }
    }));

    const classes = useStyles();

    const consolesNode = () => {
        return items.map((item, idx) => {
            return (
                <div
                    className={classes.console}
                    key={`console-list-${idx}`}
                >
                    <div
                        className={classes.itemTitle}
                        onClick={() => {
                            setFilterConsole(item.id)
                        }}
                    >
                        {item.eng_title}
                    </div>
                </div>
            );
        })
    }

        return (
            <div
                className={classes.root}
            >
            <div
                    className={classes.console}
                >
                    <div
                        className={classes.itemTitle}
                        onClick={() => {
                            setFilterConsole("")
                        }}
                    >
                        {"All Consoles"}
                    </div>
                </div>
            {consolesNode()}
            </div>
        );
    }

export default ConsoleList;
