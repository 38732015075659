import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import "./Styles/Login.scss";

const Login = ({ actions, callFetch, loggedIn, setLoggedIn, ip, setCurrentUser }) => {
    const [ email, setEmail ] = useState('');
    const [ password, setPassword ] = useState('');
    const [ remember, setRemember ] = useState(true);
    const [ loggingIn, setLoggingIn ] = useState(false);

    const useStyles = makeStyles(theme => ({
        loginContainer: {
            lineHeight: "2.5rem",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
        },
        inputGroup: {
            flexDirection: "column",
            display: "flex",
            alignItems: "end"
        },
        header: {
            textAlign: "center",
        },
        input: {
            borderRadius: "4px",
            height: "2rem",
            fontSize: ".85rem",
            marginLeft: "1rem"
        },
        submitButton: {
            maxWidth: "7rem",
            marginTop: ".5rem",
            padding: "4px 12px",
            border: "1px solid #cccccc",
            borderRadius: "4px",
            backgroundImage: "linear-gradient(to bottom, #0088cc, #0044cc)",
            color: "#fff",
        },
        buttonGroup: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
        }
    }));


  const classes = useStyles();

    useEffect(() => {
        if (loggedIn) {
            window.location = `/profile`;
        }

        // TODO: redirect to user page if logged in
        console.log("login");
            // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const checkLogin = (ev) => {
        if (email !== "" && password !== "") {
            setLoggingIn(true);
            ev.preventDefault();
            login();
        } else {
            // TODO: show error can't be blank
            console.log("CAN'T BE BLANK ASSHOLE");
        }
    }

    const login = () => {
        const loginQuery = `
query {
  login (email:"${email}", password: "${password}", remember: ${remember}, ip: "${ip}") {
    cookie
    id
    alias
    email
  }
}
    `;

        callFetch(loginQuery, {}, (response) => {
            setLoggingIn(false);
            if (response.data.login) {
                const cookie = response.data.login.cookie;
                localStorage.clear();
                if (cookie !== "") {
                    setLoggedIn(true);
                    document.cookie = "ooug=" + cookie + ";path=/; SameSite=Strict";
                    setCurrentUser(response.data.login);
                    window.location = `/profile`;
                }
            }
        });
      };

    return (
        <div className={classes.loginContainer}>
            <h1 className={classes.header}>
                {"Sign In"}
            </h1>
            <div
                className={classes.form}
            >
                <div className={classes.inputGroup}>
                    <div>
                        {"Email"}
                            <input
                                disabled={loggingIn}
                                className={classes.input}
                                name="email"
                                placeholder="email"
                                type="text"
                                onChange={(ev) => { setEmail(ev.target.value) }}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                        checkLogin(e);
                                    }
                                }}
                            />
                    </div>
                    <div>
                        {"Password"}
                            <input
                                disabled={loggingIn}
                                className={classes.input}
                                name="password"
                                placeholder="password"
                                type="password"
                                onChange={(ev) => { setPassword(ev.target.value) }}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                        checkLogin(e);
                                    }
                                }}
                            />
                </div>
            </div>
            <div className={classes.buttonGroup}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={remember}
                            onChange={() => {
                                setRemember(!remember)
                            }}
                            name="rememberMe"
                            color="primary"
                        />
                    }
                    label="Stay Signed In"
                />
                <button
                    className={classes.submitButton}
                    disabled={loggingIn}
                    onClick={checkLogin}
                >
                    {"Login"}
                </button>
                </div>
            </div>
        </div>
    );
}

export default Login;